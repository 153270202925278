<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Yleiset kulut</div>
    <receipt-table source="general"></receipt-table>
  </div>
</template>

<script>
import ReceiptTable from "../../components/Receipts/ReceiptTable";

export default {
  title: "Muut kulut",

  components: {
    ReceiptTable,
  },
};
</script>

<style scoped></style>
